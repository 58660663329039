
import React, { CSSProperties } from 'react';
import { Typography, Tooltip } from '@mui/material';

// Define the data interface
export interface RubricData {
  rubricName: string;
  beginningScore: number;
  endScore: number;
  averageScore: number;
}

// Define the Dashboard component props
interface DashboardProps {
  data: RubricData[];
  title?: string;
  message: string;
}

// Component styles as properly typed CSSProperties objects
const styles: Record<string, CSSProperties> = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '24px',
    color: '#333',
    borderBottom: '1px solid #eee',
    paddingBottom: '12px',
  },
  rubricItem: {
    marginBottom: '32px',
    borderBottom: '1px solid #eee',
    paddingBottom: '16px',
  },
  rubricName: {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '8px',
    color: '#333',
  },
  timelineContainer: {
    position: 'relative',
    marginTop: '24px',
    marginBottom: '32px',
    height: '40px',
  },
  baselineLine: {
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: '#BFDBFE', // light blue
    top: '20px',
  },
  endPoint: {
    position: 'absolute',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#BFDBFE',
    top: '20px',
    transform: 'translateY(-50%)',
  },
  leftEndPoint: {
    left: '0',
  },
  rightEndPoint: {
    right: '0',
  },
};

// Define marker diameters (in pixels)
const markerDiameters: Record<string, number> = {
  beginning: 12, // diameter 12px => radius 6px
  average: 16,   // diameter 16px => radius 8px
  end: 20,       // diameter 20px => radius 10px
};

interface RubricScorelineProps {
  rubricName: string;
  beginningScore: number;
  endScore: number;
  averageScore: number;
}

const RubricScoreline: React.FC<RubricScorelineProps> = ({
  rubricName,
  beginningScore,
  endScore,
  averageScore,
}) => {
  // Prepare markers with their label, score, color, and use the markerDiameters mapping.
  const markers = [
    { name: 'beginning', score: beginningScore, color: '#F97316' },
    { name: 'average', score: averageScore, color: '#A855F7' },
    { name: 'end', score: endScore, color: '#22C55E' },
  ];

  // Group markers by score (using score value as string key)
  const groupedMarkers = markers.reduce((acc, marker) => {
    const key = marker.score.toString();
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(marker);
    return acc;
  }, {} as Record<string, typeof markers>);

  // Render each marker group with a tooltip.
  const markerElements = Object.entries(groupedMarkers).map(([score, group]) => {
    // Tooltip content lists details for all markers at this score.
    const tooltipContent = (
      <div>
        {group.map((marker) => (
          <Typography key={marker.name} variant="body2">
            {marker.name}: {Math.round(marker.score)}
          </Typography>
        ))}
      </div>
    );

    // If more than one marker overlaps, we want them to exactly overlap.
    // Sort markers so that the largest circle is rendered first (in the background)
    if (group.length > 1) {
      group.sort((a, b) => markerDiameters[a.name] - markerDiameters[b.name]);
    }

    // For a single marker, prepare the label style.
    const labelStyle: CSSProperties = {};
    if (group.length === 1) {
      const markerSize = markerDiameters[group[0].name];
      // Place the label below the dot. (Baseline is at 0 in the container.)
      labelStyle.position = 'absolute';
      labelStyle.top = markerSize / 2 + 4; // marker radius + 4px margin
      labelStyle.left = '50%';
      labelStyle.transform = 'translateX(-50%)';
    }

    return (
      <Tooltip title={tooltipContent} arrow key={score}>
        <div
          style={{
            // Position the group container at the appropriate score along the timeline.
            position: 'absolute',
            left: `${score}%`,
            top: '20px', // baseline is at 20px
            transform: 'translateX(-50%)',
            cursor:"pointer",
            // Set relative positioning so that children with absolute positioning are placed correctly.
            // position: 'relative',
          }}
        >
          {/* Render marker dots (all overlapping) */}
          {group.map((marker) => {
            const diameter = markerDiameters[marker.name];
            return (
              <div
                key={marker.name}
                style={{
                  width: `${diameter}px`,
                  height: `${diameter}px`,
                  borderRadius: '50%',
                  backgroundColor: marker.color,
                  position: 'absolute',
                  // Place the dot so that its center aligns with the container’s (0,0)
                  top: '0',
                  left: '0',
                  transform: 'translate(-50%, -50%)',
                  cursor:"pointer",
                }}
              />
            );
          })}
          {/* If there's only one marker at this score, display its score label below the dot */}
          {group.length === 1 && (
            <Typography variant="caption" style={labelStyle}>
              {Math.round(group[0].score)}
            </Typography>
          )}
        </div>
      </Tooltip>
    );
  });

  return (
    <div style={styles.rubricItem}>
      <h3 style={styles.rubricName}>{rubricName}</h3>
      <div style={styles.timelineContainer}>
        {/* Render the timeline baseline */}
        <div style={styles.baselineLine}></div>
        {/* Render fixed start and end circles on the timeline */}
        <div style={{ ...styles.endPoint, ...styles.leftEndPoint }}></div>
        <div style={{ ...styles.endPoint, ...styles.rightEndPoint }}></div>
        {/* Render the marker groups */}
        {markerElements}
      </div>
    </div>
  );
};

const Dashboard: React.FC<DashboardProps> = ({ data, title, message }) => {
  // const [errorMessage, setErrorMessage] = useState(message);

  // useEffect(() => {
  //   setErrorMessage(message);
  // }, [message]);

  return data.length > 0 ? (
    <div style={styles.container}>
      <h2 style={styles.title}>{title}</h2>
      {data.map((rubric, index) => (
        <RubricScoreline
          key={index}
          rubricName={rubric.rubricName}
          beginningScore={rubric.beginningScore}
          endScore={rubric.endScore}
          averageScore={rubric.averageScore}
        />
      ))}
    </div>
  ) : (
    <Typography>{message}</Typography>
  );
};

export default Dashboard;


