import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';

const ffmpeg = new FFmpeg();
let isFFmpegLoaded = false;

const loadFFmpeg = async () => {
	if (!isFFmpegLoaded) {
		await ffmpeg.load();
		isFFmpegLoaded = true;
	}
};

export const extractAudioFromVideo = async (videoFile: File): Promise<File | null> => {
	if (!isFFmpegLoaded) await loadFFmpeg();

	await ffmpeg.writeFile('input.mp4', await fetchFile(videoFile));

	// Initial compression
	await ffmpeg.exec([
		'-i',
		'input.mp4',
		'-vn',
		'-acodec',
		'libmp3lame',
		'-b:a',
		'128k',
		'-ar',
		'44100',
		'-q:a',
		'4',
		'output.mp3'
	]);

	const data = await ffmpeg.readFile('output.mp3');
	if (!data || !(data instanceof Uint8Array)) {
		console.error('FFmpeg failed to extract audio.');
		return null;
	}

	const audioBlob = new Blob([data], { type: 'audio/mp3' });
	let compressedFile = new File([audioBlob], 'compressed_audio.mp3', { type: 'audio/mp3' });

	// Download the initial compression
	// downloadFile(compressedFile);

	// Check if the size is under 25MB
	if (compressedFile.size > 25 * 1024 * 1024) {
		console.warn(`Audio file exceeds 25MB (${(compressedFile.size / 1024 / 1024).toFixed(2)}MB). Reducing quality...`);
		const furtherCompressed = await furtherCompressAudio(compressedFile);
		if (furtherCompressed) {
			compressedFile = furtherCompressed;
			// downloadFile(compressedFile);
		}
	}

	// Final fallback compression if size still exceeds 25MB
	if (compressedFile.size > 25 * 1024 * 1024) {
		const lowestCompressed = await lowestQualityCompression(compressedFile);
		if (lowestCompressed) {
			compressedFile = lowestCompressed;
			// downloadFile(compressedFile);
		}
	}
	// downloadFile(compressedFile);
	
	return compressedFile;
};

/**
 * Further compresses the MP3 file if it exceeds 25MB.
 */
const furtherCompressAudio = async (audioFile: File): Promise<File | null> => {
	await ffmpeg.writeFile('large.mp3', await fetchFile(audioFile));

	await ffmpeg.exec(['-i', 'large.mp3', '-b:a', '96k', '-ar', '32000', '-q:a', '5', 'final_output.mp3']);

	const data = await ffmpeg.readFile('final_output.mp3');
	if (!data || !(data instanceof Uint8Array)) {
		console.error('FFmpeg failed to compress audio.');
		return null;
	}

	return new File([new Blob([data], { type: 'audio/mp3' })], 'compressed_audio_final.mp3', { type: 'audio/mp3' });
};

/**
 * Applies the lowest possible quality settings to the MP3 file.
 */
const lowestQualityCompression = async (audioFile: File): Promise<File | null> => {
	await ffmpeg.writeFile('lowest.mp3', await fetchFile(audioFile));

	await ffmpeg.exec([
		'-i',
		'lowest.mp3',
		'-b:a',
		'64k', // Minimum bitrate
		'-ar',
		'22050', // Lowest acceptable sample rate
		'-q:a',
		'9', // Maximum quality factor
		'lowest_output.mp3'
	]);

	const data = await ffmpeg.readFile('lowest_output.mp3');
	if (!data || !(data instanceof Uint8Array)) {
		console.error('FFmpeg failed to compress audio to the lowest quality.');
		return null;
	}

	return new File([new Blob([data], { type: 'audio/mp3' })], 'compressed_audio_lowest.mp3', { type: 'audio/mp3' });
};

/**
 * Triggers a download for a given file.
 */
const downloadFile = (file: File) => {
	const url = URL.createObjectURL(file);
	const link = document.createElement('a');
	link.href = url;
	link.download = file.name;
	link.click();
	URL.revokeObjectURL(url);
};
