import React, { useEffect, useState } from 'react';
import person from '../../Assets/Images/person2.svg';
import Statistics from '../../Assets/Images/Statistics.svg';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
// import StudentRow from './TableRow/StudentRow';
import getUser from '../../Helper/getUser';
import { getColor } from '../Progress/SessionCard/SessionCard';
import StatisticTable from './StatisticTable';
import SummaryTable from './SummaryTable';

const CoachDashboard = () => {
	const [allData, setAllData] = useState([]);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getStatistics();
	}, []);
	const [selectedPeoples, setSelectedPeoples] = useState(0);
	const [selectedCohortId, setSelectedCohortId] = useState<string | number>(0);

	const [summaryDataTable, setSummaryDataTable] = useState(false);

	// const totalSessions = allData.reduce((acc, curr: any) => acc + curr.sessions, 0);
	// const averageSessions = Math.round(totalSessions / allData.length);

	// const totalTime = allData.reduce((acc, curr: any) => acc + curr.total_time, 0);
	// const averageTime = Math.round(totalTime / allData.length);

	const totalCoachingPer =
		((100 * allData.reduce((total, item: any) => total + item?.coaching, 0)) /
			allData.reduce((total, item: any) => total + item?.total_simulation, 0)) |
		0;

	const totalCommunicationPer =
		((100 * allData.reduce((total, item: any) => total + item?.communication, 0)) /
			allData.reduce((total, item: any) => total + item?.total_simulation, 0)) |
		0;

	const getStatistics = async () => {
		if (user.id) {
			await axios
				.get(`${baseUrl}/statistics/${user.id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (res.data) {
						setAllData(res.data);
						// console.log(res.data);
						
					}
				})
				.catch(e => {
					console.log(e);
					unhandleToken(e);
				});
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '32px',
				fontFamily: 'IBM Plex Sans'
			}}
		>
			<Typography sx={{ fontWeight: 'Bold', lineHeight: '36px', fontSize: '28px' }}>Cohort Dashboard</Typography>
			<Box
				sx={{
					display: 'flex',
					gap: '16px',
					' @media(max-width:747px)': {
						flexDirection: 'column'
					}
				}}
			>
				<Box
					sx={{
						width: '331px',
						borderRadius: '12px',
						background: '#CFE9FD',
						padding: '20px',
						cursor: 'pointer',
						border: !summaryDataTable ? '3px solid #0043CE' : 'none'
					}}
					onClick={() => setSummaryDataTable(false)}
				>
					<Box>
						<div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '20px' }}>
							<Box
								component="img"
								sx={{
									background: '#fff',
									borderRadius: '8px',
									padding: '5px',
									height: 40,
									width: 40
								}}
								alt="person_logo"
								src={person}
							/>
							<Typography
								sx={{
									color: '#0043CE',
									fontSize: '20px',
									lineHeight: '28px',
									fontWeight: '800'
								}}
							>
								Activities
							</Typography>
						</div>
						<Typography
							sx={{
								color: '#0043CE',
								fontSize: '18px',
								lineHeight: '28px',
								fontWeight: '800'
							}}
						>
							Number of people
						</Typography>
					</Box>
					<Typography
						sx={{
							fontWeight: '600',
							fontSize: '34px',
							color: '#0043CE',
							lineHeight: '40px'
						}}
					>
						{selectedPeoples == 0 ? '-' : selectedPeoples}
					</Typography>
				</Box>
				<Box
					sx={{
						width: '331px',
						borderRadius: '12px',
						background: '#CFE9FD',
						padding: '20px',
						cursor: 'pointer',
						border: summaryDataTable ? '3px solid #0043CE' : 'none'
					}}
					onClick={() => setSummaryDataTable(true)}
				>
					<Box>
						<div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '20px' }}>
							<Box
								component="img"
								sx={{
									background: '#fff',
									borderRadius: '8px',
									padding: '5px',
									height: 40,
									width: 40
								}}
								alt="person_logo"
								src={Statistics}
							/>
							<Typography
								sx={{
									color: '#0043CE',
									fontSize: '20px',
									lineHeight: '28px',
									fontWeight: '800'
								}}
							>
								Summary
							</Typography>
						</div>

						{/* <Typography
							sx={{
								color: '#0043CE',
								fontSize: '20px',
								lineHeight: '28px',
								fontWeight: '800'
							}}>
							Summary
						</Typography> */}
					</Box>
					{/* <Box>
						<Box sx={{ marginBottom: '17px', display: 'flex', gap: '11px' }}>
							<Typography
								sx={{
									color: '#0043CE',
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '20px'
								}}>
								Sessions
							</Typography>
							<Typography
								sx={{
									color: '#2AB465',
									fontWeight: 'Medium',
									fontSize: '22px',
									lineHeight: '20px'
								}}>
								{averageSessions}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', gap: '11px' }}>
							<Typography
								sx={{
									color: '#0043CE',
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '20px'
								}}>
								time
							</Typography>
							<Typography
								sx={{
									color: '#E2C636',
									fontWeight: 'Medium',
									fontSize: '22px',
									lineHeight: '20px'
								}}>
								{averageTime}
							</Typography>
						</Box>
					</Box> */}
					{/* <Box>
						<Box sx={{ marginBottom: '17px', display: 'flex', gap: '11px' }}>
							<Typography
								sx={{
									color: '#0043CE',
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '20px'
								}}
							>
								Coaching
							</Typography>
							<Typography
								sx={{
									// color: '#2AB465',
									// color: '#E2C636',
									color: `${getColor(totalCoachingPer)}`,
									fontWeight: 'Medium',
									fontSize: '22px',
									lineHeight: '20px'
								}}
							>
								{totalCoachingPer}
							</Typography>
						</Box>
						<Box sx={{ display: 'flex', gap: '11px' }}>
							<Typography
								sx={{
									color: '#0043CE',
									fontWeight: '500',
									fontSize: '16px',
									lineHeight: '20px'
								}}
							>
								Communication
							</Typography>
							<Typography
								sx={{
									color: `${getColor(totalCoachingPer)}`,
									fontWeight: 'Medium',
									fontSize: '22px',
									lineHeight: '20px'
								}}
							>
								{totalCommunicationPer}
							</Typography>
						</Box>
					</Box> */}
				</Box>
			</Box>
			{summaryDataTable ? (
				<SummaryTable
					selectedCohortId={selectedCohortId}
					setSelectedCohortId={setSelectedCohortId}
					setSelectedPeoples={setSelectedPeoples}
				/>
			) : (
				<StatisticTable
					selectedCohortId={selectedCohortId}
					setSelectedCohortId={setSelectedCohortId}
					setSelectedPeoples={setSelectedPeoples}
				/>
			)}
		</Box>
	);
};

export default CoachDashboard;
