import {
	Box,
	Button,
	IconButton,
	Snackbar,
	SnackbarCloseReason,
	styled,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getToken, useHandleToken } from '../../Helper/GetToken';
import getUser from '../../Helper/getUser';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedUser } from '../../Helper/Redux/Action/userAction';
import ExcelJS from 'exceljs';
import CloseIcon from '@mui/icons-material/Close';
import { ICohort } from '../../types/cohort';
interface Scenario {
	scenario_id: number;
	scenario_name: string;
	role_name: string | null;
	video_url: string | null;
	category: string;
	description: string;
	parent_id: number | null;
	voice_modal: string | null;
}

interface Cohort {
	id: number;
	name: string;
	start_date: string | null;
	end_date: string | null;
	created_at: string;
	deleted_at: string | null;
	deleted_by: number | null;
	created_by: number;
}

interface StatisticsData {
	id: number;
	time_spended: number;
	average_session_score: number;
	scenario: Scenario;
	cohort: Cohort;
	highest_score: number;
}

interface User {
	id: number;
	firebase_id: string;
	account_status: string;
	email: string;
	first_name: string;
	last_name: string;
	created_at: string;
	deleted_at: string | null;
}

interface Statistics {
	id: number;
	name: string;
	start_date: string;
	sessions: number;
	total_time: number;
	coach_total_time: number;
	average_score: number;
	communication: number | null;
	coaching: number | null;
	total_simulation: number;
	statisticsdata: StatisticsData[];
	user: User;
	Strengths: string;
	Weaknesses: string;
}

const StatisticTable = ({ setSelectedPeoples, selectedCohortId, setSelectedCohortId }: any) => {
	const [statisticData, setStatisticData] = useState<Statistics[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [isDisabledBtn, setIsDisabledBtn] = useState(false);
	const [rows, setRows] = useState<(string | number)[][]>([]);
	const [startDate, setStartDate] = useState<string | number>();
	const [minDate, setMinDate] = useState('');
	const [endDate, setEndDate] = useState<string | number>();
	const today = new Date().toISOString().split('T')[0];
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const token = getToken();
	const user = getUser();
	const StyledLink = styled(Link)({
		textDecoration: 'none',
		color: 'inherit'
	});
	const [open, setOpen] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const dispatch = useDispatch();
	// const [selectedCohortId, setSelectedCohortId] = useState<string | number>(0);
	const [cohorts, setCohorts] = useState<ICohort[]>([]);
	const { unhandleToken } = useHandleToken();
	useEffect(() => {
		getStatistic();
		getAllCohorts();
	}, []);

	const getStatistic = async () => {
		if (user.id) {
			try {
				const res = await axios.get(`${baseUrl}/getstatistics/${user.id}`, {
					headers: {
						Authorization: token
					}
				});
				if (res.data) {
					setStatisticData(res.data);
				}
			} catch (e) {
				console.log(e);
				unhandleToken(e);
			}
		}
	};
	const getAllCohorts = async () => {
		const token = getToken();
		try {
			const response = await axios.get(`${baseUrl}/coachCohort`, {
				headers: {
					Authorization: token
				}
			});

			setCohorts(response.data);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		if (statisticData.length > 0) {
			const filtered = filterUsersBySelectedCohort(statisticData, selectedCohortId);
			const allScenarios = getAllScenarios(filtered);
			setHeaders(generateTableHeaders(allScenarios));
			setRows(generateTableRows(filtered, allScenarios));
			setSelectedPeoples(generateTableRows(filtered, allScenarios).length);
		}
	}, [statisticData, selectedCohortId]);
	useEffect(() => {
		if (cohorts.length > 0 && !selectedCohortId) {
			setSelectedCohortId(cohorts[0].id);
		}
	}, [cohorts]);

	// Handle tab change
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedCohortId(newValue);
	};

	const filterUsersBySelectedCohort = (data: any[], cohortId: number | string) => {
		return data
			.map(user => {
				// Filter `statisticsdata` for matching cohort ID
				const filteredStatisticsData = user.statisticsdata.filter(
					(stat: { cohort: { id: number } | null }) => stat.cohort && stat.cohort.id === cohortId
				);

				// If no matching data, exclude the user entirely
				if (filteredStatisticsData.length === 0) {
					return null;
				}

				// Return the user with filtered statisticsdata
				return { ...user, statisticsdata: filteredStatisticsData };
			})
			.filter(Boolean); // Remove null entries
	};
	const scenarioIds: number[] = [];

	// Function to get all unique scenario names and IDs
	const getAllScenarios = (data: Statistics[]): Set<string> => {
		const scenarios = new Set<string>();

		data.forEach(stat => {
			stat.statisticsdata.forEach(statData => {
				if (!scenarioIds.includes(statData.scenario.scenario_id)) {
					scenarioIds.push(statData.scenario.scenario_id);
				}
				scenarios.add(statData.scenario.scenario_name);
			});
		});

		return scenarios;
	};

	// Function to generate table headers
	const generateTableHeaders = (allScenarios: Set<string>): string[] => {
		const headers = ['Name', 'Start Date', 'Total Time (In Min.)'];

		return headers.concat(
			Array.from(allScenarios).flatMap(scenario => [
				`${scenario} Time Spent(In Min.)`,
				`${scenario} Score`,
				`${scenario} Best Score`
			])
		);
	};

	// Function to generate table rows
	const generateTableRows = (data: Statistics[], allScenarios: Set<string>): (string | number)[][] => {
		return data.map(stat => {
			const row: (string | number)[] = [];
			row.push(stat.user?.firebase_id);
			row.push(stat.name);
			row.push(new Date(stat.start_date)?.toLocaleDateString());
			row.push(stat.total_time?.toFixed(2));

			const scenarioData: {
				[key: string]: {
					time_spended: number | string;
					average_session_score: number | string;
					highest_score: number | string;
				};
			} = {};

			// Initialize scenario data with "N/A"
			Array.from(allScenarios).forEach(scenario => {
				scenarioData[scenario] = {
					time_spended: 'Not in cohort',
					average_session_score: 'Not in cohort',
					highest_score: 'Not in cohort'
				};
			});

			// Populate scenario data with actual values where available
			stat.statisticsdata.forEach(statData => {
				const scenario = statData?.scenario.scenario_name;
				scenarioData[scenario] = {
					time_spended: statData?.time_spended?.toFixed(2),
					average_session_score:
						statData?.average_session_score !== 0
							? ((100 * ((statData?.average_session_score as number) + 1)) / 4)?.toFixed(2)
							: '0.00',
					highest_score:
						statData?.highest_score !== 0 ? ((100 * ((statData?.highest_score as number) + 1)) / 4)?.toFixed(2) : '0.00'
				};
			});

			// Add scenario data to row
			Object.keys(scenarioData).forEach(scenario => {
				row.push(scenarioData[scenario]?.time_spended);
				row.push(scenarioData[scenario]?.average_session_score);
				row.push(scenarioData[scenario]?.highest_score);
			});

			row.push(stat.coach_total_time?.toFixed(2));
			row.push(stat.sessions);
			row.push(stat.Strengths);
			row.push(stat.Weaknesses);
			return row;
		});
	};

	const exportToExcel = async () => {
		setIsDisabledBtn(true);

		const lastheaders = [
			{ header: 'Coach Time Spend(In Min.)', key: 'Coach Time Spend(In Min.)', width: 20 },
			{ header: 'Coach Session', key: 'Coach Session', width: 20 },
			{ header: 'Strength', key: 'Strength', width: 20 },
			{ header: 'Weaknesses', key: 'Weaknesses', width: 20 }
		];

		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Statistics');

		worksheet.columns = headers?.map(header => ({ header, key: header, width: 20 })).concat(lastheaders);

		rows?.forEach(row => {
			worksheet.addRow(row?.slice(1));
		});

		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/octet-stream' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		const currentDate = new Date();
		const year = currentDate.getFullYear(); // Get the current year
		const month = currentDate.getMonth() + 1; // Get the current month (add 1 since it's zero-based)
		const day = currentDate.getDate(); // Get the current day
		const hours = currentDate.getHours(); // Get the current hour
		const minutes = currentDate.getMinutes(); // Get the current minute
		const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}_${hours.toString().padStart(2, '0')}-${minutes.toString().padStart(2, '0')}`;

		link.download = `statistics-${formattedDateTime}.xlsx`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		setIsDisabledBtn(false);
	};
	const datesubmit_Handler = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setIsDisabledBtn(true);

		try {
			const res = await axios.get(`${baseUrl}/statistics/range/${user.id}/${startDate}/${endDate}`, {
				headers: {
					Authorization: token
				}
			});

			if (res.data.message) {
				setNotificationMessage(res.data.message);
				setOpen(true);
			}
			// if (res.data) {
			// 	setStatisticData(res.data);
			// }
		} catch (error) {
			console.log(error);
			unhandleToken(error);
		} finally {
			setIsDisabledBtn(false);
		}
	};
	const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	const action = (
		<React.Fragment>
			{/* <Button color="secondary" size="small" onClick={handleClose}>
				UNDO
			</Button> */}
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);
	return (
		<div>
			<Box>
				<Box sx={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
					<Box sx={{ marginRight: '15px' }}>
						<form>
							<Box sx={{ display: 'flex', justifyContent: 'center', verticalAlign: 'center', fontSize: '15px' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<label htmlFor="startdate" style={{ fontSize: '10px' }}>
										Start Date
									</label>
									<input
										type="date"
										name="startDate"
										aria-label="startdate"
										id="startdate"
										required
										className="customData-input"
										placeholder="Start Date"
										max={today}
										onChange={e => {
											const dateValue = e.target.value;
											const timestamp = new Date(dateValue).getTime();
											setStartDate(timestamp);
											setMinDate(e.target.value);
										}}
									/>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<label htmlFor="endDate" style={{ fontSize: '10px' }}>
										End Date
									</label>
									<input
										type="date"
										name="endDate"
										aria-label="endDate"
										required
										className="customData-input"
										min={minDate}
										max={today}
										onChange={e => {
											const dateValue = e.target.value;
											const timestamp = new Date(dateValue).getTime();
											setEndDate(timestamp);
										}}
									/>
								</Box>
								<Button
									variant="contained"
									color="primary"
									disabled={isDisabledBtn || !startDate || !endDate}
									type="submit"
									onClick={datesubmit_Handler}
								>
									Send to email
								</Button>
							</Box>
						</form>
					</Box>
					<Button variant="contained" color="primary" disabled={isDisabledBtn} onClick={exportToExcel}>
						Export to Excel
					</Button>
				</Box>
				<Box
					sx={{
						marginBottom: '10px',
						borderBottom: '1px solid #e0e0e0',
						padding: { xs: '5px', sm: '5px' },
						backgroundColor: 'background.paper'
					}}
				>
					<Tabs
						value={selectedCohortId}
						onChange={handleTabChange}
						aria-label="Cohort tabs"
						variant="scrollable"
						scrollButtons="auto"
						sx={{
							'& .MuiTab-root': {
								textTransform: 'none',
								fontWeight: 'normal',
								minWidth: '80px',
								padding: { xs: '8px 16px', sm: '12px 20px' }
							},
							'& .MuiTabScrollButton-root': {
								color: 'text.secondary'
							},
							'& .Mui-selected': {
								color: 'primary.main'
							},
							'& .MuiTabs-indicator': {
								backgroundColor: 'primary.main'
							}
						}}
					>
						{cohorts.map(cohort => (
							<Tab
								key={cohort.id}
								label={cohort.name}
								value={cohort.id}
								id={`tab-${cohort.id}`}
								aria-controls={`tabpanel-${cohort.id}`}
							/>
						))}
					</Tabs>
				</Box>
				{rows.length ? (
					<TableContainer sx={{ borderRadius: '8px' }}>
						<Table>
							<TableHead
								sx={{
									background: '#cfe9fd52',
									border: '1px solid rgba(224, 224, 224, 1)'
								}}
							>
								<TableRow>
									<TableCell colSpan={3} style={{ border: '1px solid rgba(224, 224, 224, 1)' }}></TableCell>
									<TableCell
										align="left"
										colSpan={headers.length - 3}
										style={{ border: '1px solid rgba(224, 224, 224, 1)', textAlign: 'center' }}
									>
										Time Spent and Scores
									</TableCell>
									<TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} rowSpan={2}>
										Coach Time Spend
									</TableCell>
									<TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} rowSpan={2}>
										Coach Session
									</TableCell>
									<TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} rowSpan={2}>
										Strength
									</TableCell>
									<TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }} rowSpan={2}>
										Weaknesses
									</TableCell>
								</TableRow>
								<TableRow>
									{headers.map(header => (
										<TableCell align="left" key={header} style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
											{header}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, rowIndex) => (
									<TableRow key={rowIndex}>
										{row.map(
											(cell, cellIndex) =>
												cellIndex !== 0 && (
													<TableCell
														align="left"
														key={cellIndex}
														style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
													>
														<StyledLink
															to={'/progress'}
															onClick={() => row[0] !== null && dispatch(setSelectedUser(row[0]))}
														>
															{cell}
														</StyledLink>
													</TableCell>
												)
										)}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					'No data found'
				)}
			</Box>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={notificationMessage}
				action={action}
			/>
		</div>
	);
};

export default StatisticTable;
