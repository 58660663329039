import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as generateUUID } from 'uuid';
import { ChannelE, ChatContext } from '../../../Contexts';
import { addConversation, getCurrentTimeStamp, updateConversation } from '../../../Helper/';
import { IChat, IConversation } from '../../../types';
import clock from '../../../Assets/Images/clock.png';
import mike from '../../../Assets/Images/mike.svg';
import AudioRecorder from '../../../Helper/AudioRecorder';
import { useChannel } from '../../../hooks/useChannel';
import getUser from '../../../Helper/getUser';
import { useDispatch, useSelector } from 'react-redux';
import { setConversationId, setEnableVoice, setRole } from '../../../Helper/Redux/Action/userAction';
import useBackToDefault from '../../../Helper/helperFunction';
import axios from 'axios';
import Notification from '../../Notification/Notification';
import { getToken, useHandleToken } from '../../../Helper/GetToken';
import { Alert, Snackbar } from '@mui/material';
import { VoiceModalType } from '../../../types/voiceModal';
import { IValueHandler } from '../../../types/valueHandler';
import Avatar from '../../Avatar/Avatar';
import AvatarWindow from '../../Avatar/AvatarWindow';

const PrepareInput: React.FC = () => {
	const [enableMic, setEnableMic] = useState(false);
	const [Message, setMessage] = useState<string>('');
	const [callSendMsg, setCallSendMsg] = useState<boolean>(false);
	const [allMessages, setAllMessages] = useState<IChat[]>([]);
	const backtoDefault = useBackToDefault();
	const user = getUser();
	const tempUserId = user?.id;
	const [timer, setTimer] = useState(0);
	const triggerHandleEnterRef = useRef(false);
	const dispatch = useDispatch();
	const { sendMessage } = useChannel(ChannelE.simulationPrepare);
	const { roleName } = useSelector((state: any) => state?.role);
	const simulationId = useSelector((state: any) => state?.simulationId.simulationId);
	const [userTokens, setUserTokens] = useState<any>();
	const [isUserHaveTokens, setIsUserHaveTokens] = useState<boolean>(true);
	const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
	const sendInputRef: React.LegacyRef<HTMLTextAreaElement> | undefined = useRef(null);
	const token = getToken();
	const { unhandleToken } = useHandleToken();
	const [openAlert, setOpenAlert] = React.useState<{
		open: boolean;
		type?: 'success' | 'error' | 'warning' | undefined;
		message?: string;
	}>();
	const [prepPrompt, setPrepPrompt] = useState<string>();
	const [voiceModal, setVoiceModal] = useState<VoiceModalType>('alloy');

	const getPrompts = async () => {
		if (roleName?.scenario_id)
			await axios
				.get(`${baseUrl}/api/scenario/getPrompt/${roleName.scenario_id}`, {
					headers: {
						Authorization: token
					}
				})
				.then(res => {
					if (roleName?.voice_modal) {
						setVoiceModal(roleName?.voice_modal);
					}
					setPrepPrompt(res?.data?.allPrompts.prep_prompt);
				})
				.catch(e => {
					console.log(e);
				});
	};

	useEffect(() => {
		backtoDefault();
		getPrompts();
	}, []);

	const [isUpdateAudioRecorder, setIsUpdateAudioRecorder] = useState<boolean>(false);

	const updateAudioRecorder = (value: boolean) => {
		setIsUpdateAudioRecorder(value);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert({ open: false, message: '', type: openAlert?.type });
	};

	const {
		messages,
		setMessages,
		conversations,
		setConversations,
		selectedConversationId,
		setSelectedConversationId,
		chatLoading,
		setChatLoading,
		setCoachSays,
		coachSays,
		messageType,
		setMessageType,
		setDisplayTryAgain,
		setIsTheLastMessage,
		isTheLastMessage,
		setIsMessageAddedToDb,
		connection
	} = useContext(ChatContext);

	useEffect(() => {
		getUserTokens();
	}, [chatLoading]);

	const getUserTokens = async () => {
		await axios
			.get(`${baseUrl}/getUserTokens/${user.id}`, {
				headers: {
					Authorization: token
				}
			})
			.then((res: any) => {
				setUserTokens(res.data);
				setIsUserHaveTokens(res.data.remainingDailyTokens > 0);
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	const getScenarios = async () => {
		await axios
			.get(`${baseUrl}/api/scenario/allScenarios`, {
				headers: {
					Authorization: token
				}
			})
			.then(res => {
				if (res.data) {
					const role = res.data.find((m: any) => m.scenario_id === roleName?.scenario_id);
					if (!role) {
						console.error('Role not found in member list');
						return;
					}
					dispatch(setRole(role));
				}
			})
			.catch(e => {
				console.log(e);
				unhandleToken(e);
			});
	};

	useEffect(() => {
		getScenarios();
	}, []);

	const sendMsg = async (conversationId: string, messages: IChat[], msgPayload: IChat) => {
		setCallSendMsg(false);
		sendMessage({
			messages: messages,
			conversationId: conversationId,
			userId: tempUserId,
			message: msgPayload,
			roleName: roleName
		})
			.then((res: any) => {
				if (res) {
					setChatLoading(false);
					if (res.status !== 'success') {
						setOpenAlert({
							open: true,
							type: res.status,
							message: res.message || 'Something went wrong!'
						});
						setDisplayTryAgain(true);
					}
				}
			})
			.catch(e => {
				console.log('error while sending the message', e);
				setDisplayTryAgain(true);
			});
	};

	const handleSendMsg = async (payload: IChat) => {
		try {
			const msgPayload = { ...payload, messageId: generateUUID() };
			setMessages([...messages, msgPayload]);

			const conversationIdx = conversations.findIndex(
				conversation => conversation.conversationId === selectedConversationId
			);
			if (selectedConversationId !== undefined) {
				await updateConversation('simulationPrepare', tempUserId, selectedConversationId, msgPayload);
				setConversations((prevConversations: IConversation[]) => {
					prevConversations[conversationIdx].messages = [...prevConversations[conversationIdx].messages, msgPayload];
					return prevConversations;
				});
			} else {
				const newConversation = await addConversation(
					'simulationPrepare',
					tempUserId,
					msgPayload,
					roleName?.scenario_id,
					simulationId,
					roleName?.parent_id
				);
				if (newConversation) {
					setConversations(
						newConversation?.conversations.filter(items => items.scenarioId === roleName?.scenario_id) || []
					);
					setSelectedConversationId(newConversation?.id || '');
				}
			}
			setAllMessages([...messages, msgPayload]);
			setCallSendMsg(true);
			setDisplayTryAgain(false);
		} catch (error) {
			setDisplayTryAgain(true);
			console.log('error', error);
		}
	};

	const handleEnter = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			if (sendInputRef.current && sendInputRef.current.value.trim() !== '') {
				sendInputRef.current.value = '';
			} else {
				return;
			}

			if (userTokens.remainingDailyTokens === 0) {
				setIsUserHaveTokens(false);
				return;
			}

			const newChat = {
				messageType: 'client',
				message: Message,
				time: getCurrentTimeStamp()
			};
			setChatLoading(true);
			try {
				await handleSendMsg(newChat);
			} catch (e) {
				setDisplayTryAgain(true);
				setChatLoading(false);
				console.log('error while sending the message', e);
			}
		}
	};

	const [currentRole, setCurrentRole] = useState<string>('');

	const [clientMessage, setClientMessage] = useState<string>('');
	const [coachMessage, setCoachMessage] = useState<string>('');
	let isfirstCoachMessage = true;

	const [isMessagesAdded, setIsMessagesAdded] = useState<boolean>(true);
	const setIsMessageAddedTofalse = (userMessage: string, assistantMessage: string, isTheLastMessage: boolean) => {
		setIsMessagesAdded(false);
		setClientMessage(userMessage);
		setCoachMessage(assistantMessage);
		setIsTheLastMessage(isTheLastMessage);
	};

	const valueHandler = (data: IValueHandler) => {
		setCurrentRole(data.role);

		if (data.mode === 'audio') {
			setMessage(data.message);
		} else if (data.mode === 'video') {
			if (data.role === 'client') {
				setMessage(data.message);
				isfirstCoachMessage = true;
			} else {
				if (isfirstCoachMessage) {
					setMessage(data.message);
					isfirstCoachMessage = false;
				} else {
					setMessage(prevMessage => prevMessage + data.message);
				}
			}
		}
	};

	useEffect(() => {
		if (messageType === 'audio') {
			const userMsgPayload = {
				messageType: 'client',
				message: '',
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};
			const coachMsgPayload = {
				messageType: 'coach',
				message: '',
				time: getCurrentTimeStamp(),
				messageId: generateUUID()
			};

			if (selectedConversationId !== undefined) {
				if (!isMessagesAdded && connection.isConnected) {
					setMessages([...messages, userMsgPayload, coachMsgPayload]);
					setIsMessagesAdded(true);
					setMessage('');
				} else {
					const updatedMessages = [...messages];
					for (let i = messages.length - 1; i >= 0; i--) {
						if (messages[i].messageType === currentRole) {
							currentRole === 'client'
								? (updatedMessages[i] = {
										...messages[i],
										message: (messages[i].message += Message.trim())
									})
								: (updatedMessages[i] = {
										...messages[i],
										message: Message.trim()
									});
							break;
						}
					}
					setMessages(updatedMessages);
				}
			}
			setAllMessages([...messages, userMsgPayload, coachMsgPayload]);
		}
	}, [Message, currentRole, isMessagesAdded]);

	useEffect(() => {
		if (!isMessagesAdded) {
			addMessageToFirebase(clientMessage, coachMessage);
		}
	}, [isMessagesAdded]);

	const addMessageToFirebase = async (userMessage: string, assistantMessage: string) => {
		try {
			if (userMessage.trim() !== '' && assistantMessage.trim() !== '') {
				const userMsgPayload = {
					messageType: 'client',
					message: userMessage.trim(),
					time: getCurrentTimeStamp(),
					messageId: generateUUID()
				};
				const coachMsgPayload = {
					messageType: 'coach',
					message: assistantMessage.trim(),
					time: getCurrentTimeStamp(),
					messageId: generateUUID()
				};
				const conversationIdx = conversations.findIndex(
					conversation => conversation.conversationId === selectedConversationId
				);

				if (selectedConversationId) {
					setIsMessageAddedToDb(false);
					await updateConversation('simulationPrepare', tempUserId, selectedConversationId, userMsgPayload);
					await updateConversation('simulationPrepare', tempUserId, selectedConversationId, coachMsgPayload);
					if (isTheLastMessage) {
						setIsMessageAddedToDb(true);
						setIsTheLastMessage(false);
					}

					if (conversations)
						setConversations((prevConversations: IConversation[]) => {
							if (prevConversations && prevConversations[conversationIdx]?.messages)
								prevConversations[conversationIdx].messages = [
									...prevConversations[conversationIdx].messages,
									userMsgPayload,
									coachMsgPayload
								];
							return prevConversations;
						});
				}
			}
		} catch (e) {
			console.log('error while adding message to firebase', e);
		}
	};

	const createNewConversation = async () => {
		try {
			if (selectedConversationId === undefined) {
				const userMsgPayload = {
					messageType: 'client',
					message: '',
					time: getCurrentTimeStamp(),
					messageId: generateUUID()
				};
				const newConversation = await addConversation(
					'simulationPrepare',
					tempUserId,
					userMsgPayload,
					roleName?.scenario_id,
					simulationId,
					roleName?.parent_id
				);
				if (newConversation) {
					setConversations(
						newConversation?.conversations.filter(items => items.scenarioId === roleName?.scenario_id) || []
					);
					setSelectedConversationId(newConversation?.id || '');
					dispatch(setConversationId(newConversation?.id));
					return true;
				}
			}
		} catch (e) {
			console.log('error while creating conversation', e);
			return true;
		}
		return true;
	};

	const handleTimerUpdate = (newDuration: number) => {
		setTimer(newDuration);
	};

	const mikeButtonHandler = () => {
		if (!coachSays && !chatLoading) {
			setCoachSays(false);
		}
		setEnableMic(!enableMic);
	};

	useEffect(() => {
		if (Message && triggerHandleEnterRef.current) {
			handleEnter({
				key: 'Enter',
				shiftKey: false
			} as React.KeyboardEvent<HTMLTextAreaElement>);
			triggerHandleEnterRef.current = false;
		}
	}, [Message]);

	useEffect(() => {
		if (callSendMsg && messageType === 'text') {
			sendMsg(selectedConversationId as string, allMessages, allMessages?.[allMessages?.length - 1]);
		}
		setMessageType('text');
	}, [callSendMsg]);

	useEffect(() => {
		if (enableMic) {
			dispatch(setEnableVoice(true));
		}
		dispatch(setEnableVoice(false));
	}, [enableMic]);

	useEffect(() => {
		if (!chatLoading && sendInputRef.current) {
			sendInputRef.current.focus();
		}
	}, [chatLoading]);

	return (
		<div className="sender-input-box">
			{openAlert && openAlert.open && openAlert.type === 'error' && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={openAlert?.open}
					autoHideDuration={5000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity={openAlert?.type}
						variant="filled"
						sx={{
							width: '20%',
							position: 'fixed',
							top: 0,
							right: 0,
							margin: '10px',
							zIndex: 9999
						}}
					>
						{openAlert?.message}
					</Alert>
				</Snackbar>
			)}
			<div className="inputtime">
				<img src={clock} alt="clock" />
				<p>{timer}/5:00</p>
			</div>
			<div className="inputbox inputBoxPosition">
				<textarea
					ref={sendInputRef}
					className="form-control"
					placeholder="Hi, Coach!"
					disabled={chatLoading || enableMic || !isUserHaveTokens}
					rows={3}
					onChange={event => {
						setMessage(event?.target.value);
						triggerHandleEnterRef.current = false;
					}}
					onKeyDown={event => handleEnter(event)}
				/>
				{(roleName?.enable_voice_mode || roleName?.enable_avatar_mode) && (
					<button
						className="mikeButton btn circle-btn"
						onClick={() => {
							if (!coachSays && !chatLoading) {
								setCoachSays(false);
							}
							setEnableMic(!enableMic);
						}}
						style={{
							opacity: coachSays || chatLoading ? '0.5' : 1
						}}
						id="mikeButton"
						disabled={coachSays || chatLoading}
					>
						<img src={mike} alt="mike" />
					</button>
				)}
				{enableMic ? (
					<div className="micBox">
						{roleName?.enable_voice_mode && (
							<AudioRecorder
								key={isUpdateAudioRecorder ? 'update' : 'do not update'}
								valueHandler={valueHandler}
								onTimerUpdate={handleTimerUpdate}
								createNewConversation={createNewConversation}
								setIsMessageAddedTofalse={setIsMessageAddedTofalse}
								prompt={prepPrompt}
								voiceModal={voiceModal}
							/>
						)}
						{roleName?.enable_avatar_mode && (
							<Avatar
								prompt={prepPrompt}
								onTimerUpdate={handleTimerUpdate}
								valueHandler={valueHandler}
								createNewConversation={createNewConversation}
								setIsMessageAddedTofalse={setIsMessageAddedTofalse}
								setOpenAlert={setOpenAlert}
							/>
						)}
					</div>
				) : (
					''
				)}
			</div>
			{!isUserHaveTokens ? (
				<Notification text={'You have used all your daily tokens'} />
			) : (
				<p>Discuss with your coach, hit Enter to send.</p>
			)}
			{connection.isConnected && connection.status === 'connected' && connection.mode === 'video' && (
				<AvatarWindow onClick={mikeButtonHandler} />
			)}
		</div>
	);
};

export default PrepareInput;
