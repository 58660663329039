import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import SimulationCreate from './Pages/SimulationCreate';
import SimulationPrepare from './Pages/SimulationPrepare';
import SimulationMain from './Pages/SimulationMain';
import SimulationDebrief from './Pages/SimulationDebrief';
import {
	CircularProgress,
	StepLabel,
	Tooltip,
	Zoom,
	TooltipProps,
	tooltipClasses,
	Snackbar,
	Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearConversationId, clearLastConverstaion, setSimulationStep } from '../../../Helper/Redux/Action/userAction';
import { ChatContext } from '../../../Contexts';
import axiosInstance from '../../../Helper/axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import { stopAudio } from '../../../Helper/TextToSpeech';
import UserFeedbackModal from '../../UserFeedback/UserFeedback';
import axios from 'axios';
import { getToken } from '../../../Helper/GetToken';
import { IChat } from '../../../types';

const steps = ['Choose', 'Prepare', 'Simulation', 'Debrief'];

const pages = [SimulationCreate, SimulationPrepare, SimulationMain, SimulationDebrief];

// Define a type for the `completed` state
type CompletedSteps = {
	[key: number]: boolean;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#133466',
		boxShadow: theme.shadows[1],
		maxWidth: 220,
		padding: '8px',
		color: '#FFF',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '130%',
		[`& .${tooltipClasses.arrow}`]: {
			color: '#133466'
		}
	}
}));

const StepBar = () => {
	const dispatch = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [completed, setCompleted] = useState<CompletedSteps>({});
	const [analyticsLoading, setAnalyticsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [toastOpen, setToastOpen] = useState(false);
	const {
		selectedConversationId,
		conversations,
		messages,
		setSimulationFeedbackRequire,
		simulationFeedbackRequire,
		chatLoading,
		audioQueue,
		setAudioQueue,
		setPlaying,
		isPlaying,
		isTheLastMessage,
		connection,
		setDisplayTryAgain,
		lastMeetingNotesWindowAvailable,
		setIsLastMeetingNotesWindowVisible,
		prevMessages,
		setPrevMessages
	} = useContext(ChatContext);
	const buttonDisabled = isTheLastMessage || connection.isConnected;
	const totalSteps = () => steps.length;

	const navigate = useNavigate();

	const isLastStep = () => activeStep === totalSteps() - 1;
	const { state } = useLocation();
	const simulationStep = useSelector((state: any) => state.simulationStep);
	const [activeStep, setActiveStep] = useState(simulationStep.simulationStep ? simulationStep.simulationStep : 0);
	const isPreparationStep = activeStep === 1;
	const isSimulationStep = activeStep === 2;
	const isDebriefStep = activeStep === 3;
	const threshold = parseInt(process.env.REACT_APP_THRESHOLD || '10', 10); //by default value is '10'
	const { roleName } = useSelector((state: any) => state?.role);
	const { previousConversations } = useSelector((state: any) => state.previousConversations);

	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 747);
	const selectedCohort = useSelector((state: any) => state.selectedCohort?.selectedCohort);
	const simulationId = useSelector((state: any) => state?.simulationId.simulationId);

	const [lastConversationId, setLastConversationId] = useState('');
	const [stage, setStage] = useState('simulation');
	const [feedbackBtnStatus, setFeedbackBtnStatus] = useState(false);
	const token = getToken();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	useEffect(() => {
		if (state?.notDefault) {
			setActiveStep(simulationStep?.simulationStep);
		}
		const handleResize = () => {
			setIsLargeScreen(window.innerWidth > 900);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		dispatch(setSimulationStep(activeStep));
		setDisplayTryAgain(false);
	}, [activeStep]);

	useEffect(() => {
		setActiveStep(simulationStep.simulationStep);
		setDisplayTryAgain(false);
	}, [simulationStep]);
	useEffect(() => {
		const simulationId = searchParams?.get('simulationId');

		// Determine the value for lastConversationId immediately
		const updatedLastConversationId =
			simulationId && simulationId !== 'undefined'
				? simulationId
				: selectedConversationId && selectedConversationId.trim() !== ''
					? selectedConversationId
					: '';

		setLastConversationId(updatedLastConversationId);
	}, [selectedConversationId, searchParams]);

	useEffect(() => {
		dispatch(setSimulationStep(activeStep));

		if (activeStep === 3) {
			checkFeedbackAndDisableBtn();
		}
	}, [activeStep]);

	const handleNext = async () => {
		setDisplayTryAgain(false);
		stopAudio(audioQueue, setAudioQueue);
		setPlaying(false);
		isPlaying && document?.getElementById('mikeButton')?.click();
		const conversation = conversations.find(conversation => conversation.conversationId === selectedConversationId);

		// Check if the current conversation has analyticsFeedback and skip the threshold check if it does
		const hasAnalyticsFeedback = conversation?.analyticsFeedback;
		if (isSimulationStep && !hasAnalyticsFeedback && messages.length < threshold) {
			return;
		}
		if (isPreparationStep && lastMeetingNotesWindowAvailable && previousConversations.length > 0) {
			setIsLastMeetingNotesWindowVisible(true);
			return;
		}
		if (
			isSimulationStep &&
			(!conversation?.analyticsFeedback ||
				!Object.keys(conversation?.analyticsFeedback?.analytics) ||
				simulationFeedbackRequire)
		) {
			try {
				setAnalyticsLoading(true);
				await axiosInstance.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/simulation/analytics`, {
					conversationId: selectedConversationId,
					senarioId: roleName?.scenario_id,
					cohortId: selectedCohort,
					prevMessages: prevMessages,
					prevAnalytics: conversation?.analyticsFeedback,
					simulationId
				});
				setAnalyticsLoading(false);
				setPrevMessages(conversation?.messages as IChat[])
			} catch (error) {
				setAnalyticsLoading(false);
				console.error('Error uploading file:', error);
			}
		}

		const newActiveStep = isLastStep() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
		setActiveStep(newActiveStep);
		setSimulationFeedbackRequire(false);
		dispatch(clearLastConverstaion());
		dispatch(clearConversationId());
		if (newActiveStep === 3) {
			setStage('simulation');
			checkFeedbackAndOpenModal(selectedConversationId, 'simulation', false);
		}
		if (isSimulationStep) {
			navigate(`/simulations?simulationId=${selectedConversationId}`, { state: { notDefault: false } });
		} else {
			navigate('/simulations', { state: { notDefault: false } });
		}
		// setIsModalOpen(true);
	};
	const checkFeedbackAndOpenModal = async (selectedConversationId?: string, stages?: string, showmsg?: boolean) => {
		const selectedId = selectedConversationId ? selectedConversationId : lastConversationId;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/feedback/check/${selectedId}/${stages}`,
				{
					headers: {
						Authorization: token
					}
				}
			);
			if (stage === 'debrief') {
				setFeedbackBtnStatus(response.data.feedbackExists);
			}
			// Check if feedback does not exist based on response
			if (!response.data.feedbackExists) {
				setIsModalOpen(true); // Open modal if no feedback exists
			} else if (showmsg) {
				setToastOpen(true);
			}
		} catch (error) {
			console.error('Error checking feedback:', error);
			// Optional: Handle error (e.g., show error message)
		}
	};
	const handleBack = () => {
		setDisplayTryAgain(false);
		stopAudio(audioQueue, setAudioQueue);
		setPlaying(false);
		isPlaying && document?.getElementById('mikeButton')?.click();
		dispatch(clearLastConverstaion());
		dispatch(clearConversationId());
		setActiveStep((prevActiveStep: number) => Math.max(prevActiveStep - 1, 0));
		if (isDebriefStep) {
			navigate(`/simulations?simulationId=${selectedConversationId}`, { state: { notDefault: false } });
		} else {
			navigate('/simulations', { state: { notDefault: false } });
		}
	};

	const changeCurrent = (active: React.SetStateAction<number>) => {
		stopAudio(audioQueue, setAudioQueue);
		setPlaying(false);
		isPlaying && document?.getElementById('mikeButton')?.click();

		if (!nextButtonDisabled || active <= activeStep) {
			setActiveStep(active);
			navigate('/simulations', { state: { notDefault: false } });
		}
	};

	const toDebrief = (active: React.SetStateAction<number>) => {
		stopAudio(audioQueue, setAudioQueue);
		setPlaying(false);
		isPlaying && document?.getElementById('mikeButton')?.click();

		if (!nextButtonDisabled) {
			setActiveStep(active);
			navigate(`/simulations?simulationId=${selectedConversationId}`, { state: { notDefault: false } });
		}
	};

	const conversation = conversations.find(conversation => conversation.conversationId === selectedConversationId);
	const hasAnalyticsFeedback = conversation?.analyticsFeedback;
	const handleClose = () => setIsModalOpen(false);

	const nextButtonDisabled =
		isLastStep() ||
		(!selectedConversationId && isSimulationStep) ||
		analyticsLoading ||
		((isSimulationStep || isPreparationStep) && isTheLastMessage) ||
		((isSimulationStep || isPreparationStep) && connection.isConnected) ||
		(isSimulationStep && messages.length < threshold && !hasAnalyticsFeedback) ||
		(isPreparationStep && !lastMeetingNotesWindowAvailable && previousConversations.length > 0);

	const backButtonDisabled =
		((isSimulationStep || isPreparationStep) && isTheLastMessage) ||
		((isSimulationStep || isPreparationStep) && connection.isConnected) ||
		activeStep === 0;

	const checkFeedbackAndDisableBtn = async () => {
		const selectedId = selectedConversationId ? selectedConversationId : lastConversationId;
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND_BASE_URL}/feedback/check/${selectedId}/${'debrief'}`,
				{
					headers: {
						Authorization: token
					}
				}
			);

			// Check if feedback does not exist based on response
			setFeedbackBtnStatus(response.data.feedbackExists); // Open modal if no feedback exists
		} catch (error) {
			console.error('Error checking feedback:', error);
			// Optional: Handle error (e.g., show error message)
		}
	};

	return (
		<div>
			<Box sx={{ width: '100%', marginTop: '30px' }}>
				{/* Stepper Bar - Fixed at the top */}
				<Box
					sx={
						isLargeScreen && activeStep !== 0
							? {
									width: `calc(100% - 350px)`,
									margin: '0 auto',
									position: 'fixed', // Make the stepper fixed
									top: 69, // Fix it to the top of the screen
									left: 350,
									right: 0,
									px: '32px',
									paddingBottom: '20px',
									paddingTop: '30px',
									// zIndex: 1000, // Ensure it stays above other content
									backgroundColor: '#fff', // Background color to avoid transparency
									'@media (max-width: 768px)': {
										px: '10px'
									}
								}
							: {
									width: '100%',
									margin: '0 auto 30px auto',
									position: 'relative',
									px: '32px',
									'@media (max-width: 768px)': {
										px: '10px'
									}
								}
					}>
					{/* Only render buttons and stepper if not the first step */}
					{activeStep !== 0 ? (
						<Box>
							<Button
								variant="outlined"
								sx={{ position: 'absolute', left: '10px', ...styles.Button }}
								onClick={handleBack}
								disabled={backButtonDisabled || chatLoading}>
								Back
							</Button>

							<CustomTooltip
								TransitionComponent={Zoom}
								arrow
								title={
									nextButtonDisabled && activeStep === 2
										? `Please continue the current conversation up to the ${threshold} messages to get a debrief.`
										: ''
								}
								placement="left">
								<span style={{ position: 'absolute', right: '10px' }}>
									{activeStep === 3 ? (
										<Button
											variant="contained"
											sx={{ ...styles.Button }}
											disabled={feedbackBtnStatus}
											onClick={() => {
												// setIsModalOpen(true)
												setStage('debrief');
												checkFeedbackAndOpenModal(selectedConversationId, 'debrief', true);
											}}>
											Feedback
										</Button>
									) : (
										<Button
											variant="contained"
											sx={{ ...styles.Button }}
											onClick={handleNext}
											disabled={nextButtonDisabled || chatLoading}>
											Next {analyticsLoading ? <CircularProgress sx={{ marginLeft: '8px' }} size={'14px'} /> : ''}
										</Button>
									)}
								</span>
							</CustomTooltip>

							{/* Stepper Component */}
							<Stepper activeStep={activeStep} alternativeLabel sx={{ maxWidth: '400px', mx: 'auto' }}>
								{steps.map((label, index) => (
									<Step
										key={label}
										completed={!!completed[index]}
										onClick={() =>
											chatLoading || buttonDisabled
												? null
												: index === 3
													? selectedConversationId
														? toDebrief(index)
														: ''
													: changeCurrent(index)
										}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</Box>
					) : (
						''
					)}
				</Box>

				{/* Scrolling Content */}
				<Box
					sx={{
						marginTop: '100px', // Add margin to account for fixed stepper height
						// px: '32px',
						'@media (max-width: 768px)': {
							px: '10px'
						}
					}}>
					{pages.map((PageComponent, index) => {
						if (index !== activeStep) return null;
						return <PageComponent key={index} setActiveStep={setActiveStep} />;
					})}
				</Box>
			</Box>
			<UserFeedbackModal
				open={isModalOpen}
				onClose={handleClose}
				cohortId={roleName?.cohortId} // Pass actual values as needed
				scenarioId={roleName?.parent_id} // Pass actual values as needed
				childScenarioId={roleName?.scenario_id} // Pass actual values as needed
				stage={stage} // Pass actual values as needed
				conversationId={lastConversationId}
				checkstatus={checkFeedbackAndDisableBtn}
			/>
			<Snackbar
				open={toastOpen}
				autoHideDuration={3000}
				onClose={() => setToastOpen(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={() => setToastOpen(false)} severity="error" sx={{ width: '100%' }}>
					You have already submitted review for this session.
				</Alert>
			</Snackbar>
		</div>
	);
};

const styles = {
	Button: {
		borderRadius: '100px',
		border: '1px solid #1C75BA',
		p: '4px 20px',
		fontSize: '18px',
		fontWeight: 500,
		'@media (max-width: 1600px)': {
			fontSize: '15px'
		}
	}
};
export default StepBar;
