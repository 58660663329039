import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {
	RadarChart,
	Radar,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

interface Cohort {
	levels: {
		level: string;
		scores: {
			dimension: string;
			firstScore: number;
			highestScore: number;
		}[];
	}[];
}

interface SpiderGraphProps {
	cohorts: Cohort[];
	message: string;
}

const SpiderGraph: React.FC<SpiderGraphProps> = ({ cohorts = [], message }) => {
	const [chartData, setChartData] = useState<{ dimension: string; first: number; best: number }[]>([]);
	const [errorMessage, setErrorMessage] = useState(message);
	useEffect(() => {
		setChartData([]);
		if (cohorts.length > 0) {
			const formattedData = cohorts.reduce(
				(acc, cohort) => {
					(cohort.levels || []).forEach(level => {
						level.scores.forEach(score => {
							acc.push({
								dimension: `Level ${level.level} - ${score.dimension}`,
								first: Number(((score.firstScore + 1) * 25).toFixed(0)),
								best: Number((((score.highestScore + 1) * 25)).toFixed(0))
							});
						});
					});
					return acc;
				},
				[] as { dimension: string; first: number; best: number }[]
			);
			setChartData(formattedData);
			setErrorMessage(formattedData.length ? '' : 'No data available for the selected cohort.');
		}
	}, [cohorts]);
	useEffect(() => {
		setErrorMessage(message);
	}, [message]);

	return (
		<Box>
			{chartData.length > 0 ? (
				<ResponsiveContainer width="100%" height={500}>
					<RadarChart data={chartData}>
						<PolarGrid gridType="polygon" />
						<PolarAngleAxis dataKey="dimension" tick={{ fontSize: 12 }} />
						<PolarRadiusAxis angle={30} domain={[0, 100]} tickFormatter={value => `${value}`} />
						<Tooltip formatter={(value: number) => `${value}`} />
						<Radar name="First Session" dataKey="first" stroke="#dc2626" fill="#dc26264d" strokeWidth={2} />
						<Radar name="Best Session" dataKey="best" stroke="#2563eb" fill="#2564eb12" strokeWidth={2} />
						<Legend />
					</RadarChart>
				</ResponsiveContainer>
			) : (
				<Typography>{errorMessage}</Typography>
			)}
		</Box>
	);
};

export default SpiderGraph;
